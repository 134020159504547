import React from "react";
import Lottie from "react-lottie";
const animationData = require("./blogging-writing-typing.json");
export default function EducationImg() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return <Lottie options={defaultOptions} />;
}
