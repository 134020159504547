import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import CertificationCard from "../../components/certificationCard/CertificationCard";
import "./Certifications.css";

function Certifications(props) {
  const theme = props.theme;
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@secmuhammed"
    )
      .then((res) => res.json())
      .then((res) => {
        setArticles((prevState) =>
          prevState.concat(
            res.items.map((article) => ({
              title: article.title,
              link: article.link,
              thumbnail: article.thumbnail,
            }))
          )
        );
      });
    fetch("https://dev.to/api/articles?username=secmohammed")
      .then((res) => res.json())
      .then((articles) => {
        setArticles((prevState) =>
          prevState.concat(
            articles.map((article) => ({
              title: article.title,
              link: article.url,
              thumbnail: article.social_image,
            }))
          )
        );
      });
  }, []);

  return (
    <div className="main" id="certs">
      <div className="certs-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="certs-header" style={{ color: theme.text }}>
            Articles.
          </h1>
        </Fade>
      </div>
      {articles.length === 0 ? (
        <div className="certs-body-div">
          <div className="logo_wrapper">
            <div className="loading-cert">
              <div class="ball"></div>
              <div class="ball"></div>
              <div class="ball"></div>
              <div class="ball"></div>
              <div class="ball"></div>
              <div class="ball"></div>
              <div class="ball"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="certs-body-div">
          {articles.map((cert) => {
            return (
              <CertificationCard
                certificate={cert}
                theme={theme}
                key={cert.title}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Certifications;
