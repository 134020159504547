import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Certifications from "../../containers/certifications/Certifications";
import EducationImg from "./EducationImg";
import "./EducationComponent.css";
import { Fade } from "react-reveal";

function Education(props) {
    const theme = props.theme;
    return (
        <div className="education-main">
            <Header theme={props.theme} setTheme={props.setTheme} />
            <div className="basic-education">
                <Fade bottom duration={2000} distance="40px">
                    <div className="heading-div">
                        <div className="heading-img-div">
                            <EducationImg />
                        </div>
                        <div className="heading-text-div">
                            <h1
                                className="heading-text"
                                style={{ color: theme.text }}
                            >
                                Blog
                            </h1>

                            <p
                                className="experience-header-detail-text subTitle"
                                style={{ color: theme.secondaryText }}
                            >
                                I write articles from time to time about various
                                topics to share my knowledge with the community.
                            </p>
                        </div>
                    </div>
                </Fade>
                <Certifications theme={props.theme} />
            </div>
            <Footer theme={props.theme} />
        </div>
    );
}

export default Education;
