/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Mohammed",
  nickname: "Osama",
  full_name: "Mohammed Osama",
  subTitle:
    "Application Developer, Open Source Enthusiast 🔥. Always learning.",
  resumeLink:
    "https://docs.google.com/document/d/1A_jWRG74Rst427tg1izLa6vRXclS9_9F856jWJPJlDY/edit?usp=sharing",
  mail: "mailto:mohammedosama@ieee.org",
};

const socialMediaLinks = {
  github: "https://github.com/secmohammed",
  linkedin: "https://www.linkedin.com/in/secmohammed/",
  gmail: "mohammedosama@ieee.org",
  facebook: "https://www.facebook.com/Flare.SecTheater/",
  twitter: "https://twitter.com/secmuhammed",
  medium: "https://medium.com/@secmuhammed",
  dev: "https://dev.to/secmohammed",
  udemy: "https://udemy.com/sectheater",
  youtube: "https://youtube.com/sectheater",
};

const skills = {
  data: [
    {
      title: "Application Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Automating CI/CD Processes and collobrate with non-technical teams to provide them useful reports/insights of our application",
        "⚡ Building complex websites on the front end using ReactJS/VueJS",
        "⚡ Developing mobile applications using React Native",
        "⚡ Creating application backend in Golang, Nodejs & PHP",
        "⚡ Building Applications with highest standards in terms of backend/frontend",
      ],
      softwareSkills: [
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#0000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:next-dot-js",
          style: {
            color: "#FFFFF",
          },
        },
        {
          skillName: "NuxtJS",
          fontAwesomeClassname: "simple-icons:nuxt-dot-js",
          style: {
            color: "#00C58E",
          },
        },
        {
          skillName: "NestJS",
          fontAwesomeClassname: "simple-icons:nestjs",
          style: {
            color: "#E0234E",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "VueJS",
          fontAwesomeClassname: "simple-icons:vue-dot-js",
          style: {
            color: "#4FC08D",
          },
        },
        {
          skillName: "Vuetify",
          fontAwesomeClassname: "simple-icons:vuetify",
          style: {
            color: "#1867C0",
          },
        },

        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#FF2D20",
          },
        },

        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            color: "#FCC624",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#DC382D",
          },
        },
        {
          skillName: "Webpack",
          fontAwesomeClassname: "simple-icons:webpack",
          style: {
            color: "#8DD6F9",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Vercel",
          fontAwesomeClassname: "simple-icons:vercel",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Digital Ocean",
          fontAwesomeClassname: "simple-icons:digitalocean",
          style: {
            color: "#0080FF",
          },
        },
        {
          skillName: "Google Cloud Platforms",
          fontAwesomeClassname: "simple-icons:googlecloud",
        },
        {
          skillName: "OVH",
          fontAwesomeClassname: "simple-icons:ovh",
          style: {
            color: "#123F6D",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "Bitbucket Pipelines",
          fontAwesomeClassname: "simple-icons:bitbucket",
          style: {
            color: "#2684FF",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Shantilal Shah Engineering College",
      subtitle: "Bachelor in Information Technology",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I'm currently pursuing my bachelors in Information Technology.",
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ssgec.ac.in/",
    },
    {
      title: "Dr. S. & S. S. Ghandhy College Of Engineering & Technology",
      subtitle: "Diploma in Information Technology",
      logo_path: "ssgandhy.png",
      alt_name: "SSGC",
      duration: "2018 - 2021",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ssgc.cteguj.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "M0001: MongoDB Basics",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Hackathon",
      subtitle: "Hack The Mountains",
      logo_path: "hackathon1.svg",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "hackathon",
      color_code: "#E2405F",
    },
    {
      title: "A300: Atlas Security",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Cyber Security & Cyber Forensics",
      subtitle: "Workshop at IIT Bombay",
      logo_path: "iit.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "MLH Local Hack Day: Build",
      subtitle: "Major League Hacking",
      logo_path: "mlh-logo.svg",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "Google",
      color_code: "#fe0037",
    },
    {
      title: "Hack20",
      subtitle: "Flutter International Hackathon",
      logo_path: "flutter.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Flutter International Hackathon",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Senior Technical Screener",
          company: "Andela",
          company_url: "https://andela.com",
          logo_path: "andela.svg",
          duration: "Feb 2022 - Present",
          location: "Remote",
          description: `
              <p>Interviewing Candidates in PHP,NodeJS, ReactJS, VueJS, Golang</p>
              <ul>
                  <li>Consistently conduct live technical interviews with applied talents. </li>
                  <li> Review and evaluate code submissions.</li>
                  <li> Improving our technical interview process and implementing new methods of testing talents. </li>

              </ul>
            `,
          color: "#0071C5",
        },
        {
          title: "Lead Software Engineer",
          company: "Scaleup365",
          company_url: "https://scaleup365.com",
          logo_path: "scaleup.jpeg",
          duration: "Jul 2021 - Present",
          location: "Office",
          description: `
            <p>Provide technical leadership and guidance to software development operations covering product enhancement, bug fixes, data migrations, guideline setups, identification of opportunities for continuous process improvement, and implementation of measure for ensuring adherence to industry best practices. Oversee the development of new processes for technical unit, creation of software architecture and designs, delegation of tasks across the software development life cycle, production of clean and efficient code, and execution of verification and validation tests.</p>
            <ul>
                <li> Improved the efficiency of the software development process through the automation of crucial procedures using the latest scripting methods and tools. </li>
                <li> Enabled the modernization of software through the incorporation of modern technologies and tracking systems.</li>
                <li> Spearheaded the transition of Legacy PHP Native project to NestJS/GraphQL. </li>
                <li> Led the development and rollout of React and Node.JS guidelines in accordance with Airbnb requirements,
                together with the incorporation of custom architecture (DDD with event-driven architecture) for Backend Developer usage. </li>
                <li> Determine process improvements, best practices, and develop new pro- cesses for techincal side. </li>
                <li> Enforced Backend developers to use a custom architecture (DDD) </li>
                <li> Align business Side with Technical Side and ensure deliverables are what business needed </li>
            </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "Senior Software Engineer",
          company: "Knoetic",
          company_url: "https://knoetic.com",
          logo_path: "knoetic.svg",
          duration: "Dec 2021 - Jun 2022",
          location: "Remote",
          description: `
              <p>Working at the extractor team to deal with various HRIS APIs to extract data using Golang. Performed maintenance and regular updating of our internal tools with functionalities that serve our team during extracting data</p>
              <ul>
                  <li>Extracted APIs such as Jobvite, UKG, Dayforce into snowflake. </li>
                  <li> maintained our internal tools to accelerate our extraction development.</li>
                  <li>maintained our config service (developed in NodeJS). </li>
                  <li> Improved our config service and provide technical architecture suggestion. </li>
              </ul>
            `,
          color: "#0071C5",
        },
        {
          title: "Online Instructor",
          company: "SecTheater",
          company_url: "#",
          logo_path: "sec.jpeg",
          duration: "Sep 2016 - Present",
          location: "WFH",
          description: `
          <ul>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.udemy.com/laravel-best-practices-and-design-patterns" target="_blank" rel="noopener noreferrer">Laravel Best Practices & Design Patterns</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.udemy.com/practise-vuejs-vuex-laravel-through-application" target="_blank" rel="noopener noreferrer">Mastering Vuex</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.udemy.com/advanced-blogger-with-sentinel-and-laravel-55" target="_blank" rel="noopener noreferrer">Laravel Blogger with Sentinel</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.udemy.com/learn-laravel-55-from-scratch/" target="_blank" rel="noopener noreferrer">Learn Laravel From Scratch</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.udemy.com/the-onion-routing-inside/" target="_blank" rel="noopener noreferrer">The Onion Routing Inside</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.youtube.com/playlist?list=PL7mt2FDjAkPfrqMODZFOWSqX7Cvy2hb6e/" target="_blank" rel="noopener noreferrer">Laravel Tips</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.youtube.com/playlist?list=PL7mt2FDjAkPfuHqTlWrn6JrhrG3PxLcEQ/" target="_blank" rel="noopener noreferrer">Laravel ACL</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.youtube.com/playlist?list=PL7mt2FDjAkPc0zYWg3_UcZr4YiWHx1z48" target="_blank" rel="noopener noreferrer">PHP PDO</a></p></li>
            <li><p class="experience-card-company" style="color: rgb(141, 141, 141);"><a href="https://www.youtube.com/playlist?list=PL7mt2FDjAkPc6_LKwQ7YstVyt8_I4Kajj" target="_blank" rel="noopener noreferrer">PHP Basics</a></p></li>
          </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "Senior Software Engineer",
          company: "StartInfinity",
          company_url: "http://startinfinity.com/",
          logo_path: "startinf.jpeg",
          duration: "Jun 2021 - Aug 2021",
          location: "Work From Home",
          description: `
          <ul>
            <li> Develop high-quality software design and architecture</li>
            <li> Ensure software is up-to-date with latest technologies </li>
            <li> Collaborate with internal teams and vendors to fix and improve products </li>
            <li> Design, develop, modify, implement, and support software components anywhere in the software stack </li>
          </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "Tech Lead",
          company: "Joovlly",
          company_url: "http://joovlly.com/",
          logo_path: "joovlly.png",
          duration: "May 2020 - Jun 2021",
          location: "Work From Home",
          description: `
          <p>
          Supervised the establishment of KPIs, technical processes, PHP, PSR standards, Laravel best practices, and React guidelines in accordance with Airbnb's naming conventions and code style, along with the implementation of Agile terminology and GitFlow. Conducted review and analysis of code, while applying CI/CD via Envoyer and Github Actions. Designed and coded solutions. Provided technical training to both front and back-end developers. Facilitated correspondences with product owner regarding project features, plans, and prioritisation.</p>
          <ul>
            <li>Enabled the usage of custom architecture (DDD/Atomic Design) as part of an effort for incorporating modern organisational standards and best practices.</li>
            <li> Developed test suites and comprehensive benchmarks for more efficient delivery of performant solutions, along with custom backend layer to utilise ODOO implementation and deliver custom workflows and UI. </li>
            <li> Played a key role in the development of base Laravel, Node.JS, and React packages.</li>
            <li>Applied GitFlow and code reviewed through pull requests.</li>
            <li>Applied CI/CD through Github Actions and Envoyer.</li>
            <li>Enforced and improve organizational development standards and best practices.</li>
            <li>Design and code solutions to meet defined business requirements.</li>
            <li>Plan and prioritize project features with product owner, and provide an estimation of sprints.</li>
            <li>Mentored and led a team of backend/frontend developers.</li>
            <li>Planned & Participated in developing the base laravel/nodejs/react packages to use at most of our projects.</li>
            <li>  Periodically Writing test suites for applications either on front-end or on backend..</li>
            <li>  Applied Several benchmarks to deliver performant solutions at certain cases.</li>
          </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "Technical Consultant",
          company: "FudexEBS",
          company_url: "http://fudexebs.com.sa",
          logo_path: "fudex.png",
          duration: "Jun 2020- Feb 2021",
          location: "Work From Home",
          description: `
          <ul>
            <li>Helped at initial estimations when they weren’t accurate.</li>
            <li> Provided Code review at certain applications to designate the code flows and security issues. </li>
            <li> Provided Technical review at certain applications that serves business sit- uation </li>
            <li>Provided a consultancy concerning applications on how to apply certain business logic. </li>
          </ul>
          `,
          color: "#0071C5",
        },

        {
          title: "Software Engineer",
          company: "GraphQL",
          company_url: "https://graphql.org/",
          logo_path: "graphql.png",
          duration: "Jun 2020 - Aug 2020",
          location: "WFH",
          description: `
          <ul>
            <li>Participated on writing unit tests for graphiql.</li>
            <li> Helped with creating a custom persisted layer which accepts multiple drivers to cache data at front-end. </li>
            <li>Created a custom state management solution to be used at graphiql.</li>
          </ul>
          `,
          color: "#0071C5",
        },

        {
          title: "Tech Lead",
          company: "FudexEBS",
          company_url: "http://fudexebs.com.sa",
          logo_path: "fudex.png",
          duration: "Oct 2019 - May 2020",
          location: "WFH/WFO",
          description: `
          <p>Directed all aspects of daily operation for a multidisciplinary team of 30 developers in orchestrating React Native, Native Mobile, and front/back-end development. Organized project planning and prioritisation in collaboration with product owners. Reviewed and analysed code, while providing crucial support to the development of e-commerce base product. Oversaw server maintenance processes, and wrote application test suites.</p>
          <ul>
            <li>Ensured compliance of development processes with relevant regulatory standards and best practices.</li>
            <li>Designed project architecture and enabled use of custom architecture. </li>
            <li>Created a base e-commerce solution for the company using Laravel on the backend, and ADR for architecture
            instead of MVC.</li>

          </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "PHP Team Lead",
          company: "Zeidex",
          company_url: "http://zeidex.com/",
          logo_path: "zeidex.jpeg",
          duration: "Aug 2019 - Oct 2019",
          location: "WFO",
          description: `
          <ul>
            <li>Enforced and improve organizational development standards and best practices </li>
            <li>Design and code solutions to meet defined business requirements</li>
            <li>Plan and prioritize project features with product owner, and provide an estimation of sprints</li>
            <li>Code Review</li>
            <li>Enforced Backend developers to use a custom architecture (ADR)</li>
            <li>Periodically Writing test suites for applications.</li>
          </ul>
          `,
          color: "#0071C5",
        },
        {
          title: "TSSE Software Engineer (Intern)",
          company: "Vodafone Intelligent Solutions",
          company_url: "http://vodafone.com/",
          logo_path: "vodafone.png",
          duration: "Feb 2019 - Apr 2019",
          location: "Office",
          description: `
          <ul>
            <li>Creating an application that’s in charge of reserving rooms for Vodafone employees using Nodejs for the backend and Vuejs for front-end, also testing the backend using mocha + chai and creating the mobile application using react- native</li>
          </ul>
          `,
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Ambassador",
          company: "IEEExtreme",
          company_url: "http://www.ieeextreme.org/",
          logo_path: "ieeextreme.jpeg",
          duration: "May 2018 - Present",
          location: "",
          description: `
            IEEEXtreme is a global challenge in which teams of IEEE Student members – advised and proctored by an IEEE member, and often supported by an IEEE Student Branch, compete in a 24-hour time span against each other to solve a set of programming problems.
          <ul>
            <li>Head Of Fund Raising</li>
            <li>FR Member</li>
          </ul>
          `,
          color: "#196acf",
        },
        {
          title: "Ambassador",
          company: "IEEEMadC",
          company_url: "https://maps.google.com/localguides/",
          logo_path: "ieee-madc.png",
          duration: "Jul 2019 - Feb 2020",
          location: "Work From Home",
          description:
            "IEEEmadC (Mobile Applications Development Contest) is an international con- test organized by IEEE for all students across the globe.",
          color: "#196acf",
        },
        {
          title: "Cu-ration Member",
          company: "TEDx S.H.A",
          company_url: "#",
          logo_path: "tedx-sha.png",
          duration: "Jul 2019 - Feb 2020",
          location: "",
          description:
            "Taking the advantage of hunting speakers for TEDx event, and deciding whether the speaker is qualified for the talk or not.",
          color: "#D83B01",
        },
        {
          title: "Technical Moderator",
          company: "IEEE S.H.A",
          company_url: "#",
          logo_path: "ieee-sha.png",
          duration: "May 2018 - Feb 2020",
          location: "",
          color: "#196acf",

          description: `
                Web Development Workshop
                <ul>
                    <li> understanding the web developers’ career and how to designate your cup of tea.</li>
                    <li> Understanding The basics of HTML,CSS, JS , PHP , PDO.</li>
                    <li> Creating a blog using these technologies.</li>
                </ul>
                Linux Fundamentals<
                <ul>
                    <li>The history of Linux.</li>
                    <li>How to install Linux</li>
                    <li> How to use the Graphical User Interface.</li>
                    <li> Understanding Kernel.</li>
                    <li>How to control Linux using the command line</li>
                    <li>Basic Linux security</li>
                    <li>Permissions & Groups</li>
                    <li>Customizing your own GUI</li>
                    <li>Customizing Network Basics.</li>
                </ul>
          `,
        },
        {
          title: "Web Officer Coordinator",
          company: "IEEE Young Professionals",
          company_url: "http://ieeeypegypt.org/",
          logo_path: "ieee-yp.jpeg",
          duration: "Mar 2018 - Jul 2018",
          location: "",
          description:
            "developed EED with a team of developers, Egyptian engineering day is a shared hub to exchange best-practices and induce new ideas that promote the engineer- ing science and profession in Egypt. This prestigious annual gathering provides graduating students with an exclusive chance to market their projects, and explore the latest top-notch technologies & solutions that fit the current market needs.",
          color: "#196acf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Laravel/NodeJS Backend Projects, Golang Scripts, Vue Project, and React Project. Below are some of my projects. Note that these are the available samples that can be public.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "harikrushn.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "1",
      name: "golang-twitter-clone",
      url: "https://github.com/secmohammed/go-twitter-clone",
      description:
        "A Golang Twitter clone using Golang and PostgreSQL, exposing API endpoints to leverage the most common twitter endpoints such as Feeds, Following, Replying To Tweets, Real Time Feeds.",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "2",
      name: "Meetups Clone",
      url: "https://github.com/secmohammed/golang-graphql-meetups",
      description:
        "A Meetup.com clone, which covers most of the backend of meetup features such as creating events, groups and publish events to groups. Also, commenting on events and attending them, sending reminders, authorization and privacy for users/groups.",
      languages: [
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "Redis",
          iconifyClass: "logos-redis",
        },
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      id: "3",
      name: "Anonmyous Message Board",
      url: "https://github.com/secmohammed/anonymous-message-board-golang",
      description:
        "Creating a thread with anonymous comments by users using Golang and Gin.",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "4",
      name: "Blog",
      url: "https://github.com/secmohammed/blog-with-golang-nuxtjs",
      description:
        "A simple blog using Golang for the API, and consuming this API using NuxtJS",
      languages: [
        {
          name: "NuxtJS",
          iconifyClass: "logos-nuxt-icon",
        },
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
      ],
    },
    {
      id: "3",
      name: "Port Scanner",
      url: "https://github.com/secmohammed/port-scanner-go",
      description: "Simple CLI Port Scanner",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
      ],
    },
    {
      id: "4",
      name: "Scaling up Horitonzally PoC",
      url: "https://github.com/secmohammed/go-scaling-up-horizontally-poc",
      description:
        "Applying Horizontal Scaling Concepts on a simple application through Golang, such as Caching, Serivce Discovery, Load balancer and Centeral Log Service",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
      ],
    },
    {
      id: "5",
      name: "Podcast Itunes API",
      url: "https://github.com/secmohammed/golang-itunes-podcast",
      description: "Simple GraphQL Wrapper of Itunes API.",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
      ],
    },
    {
      id: "6",
      name: "Microservice E-commerce",
      description:
        "Using NestJS and microservices pattern to create a couple of services that communicate with each other",
      url: "https://github.com/secmohammed/microservices-e-commerce-nest-api",
      languages: [
        {
          name: "NestJS",
          iconifyClass: "logos-nestjs",
        },
        {
          name: "Typescript",
          iconifyClass: "logos-typescript-icon",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "Redis",
          iconifyClass: "logos-redis",
        },
      ],
    },
    {
      id: "7",
      name: "Slack Clone",
      url: "https://github.com/secmohammed/slack-clone",
      description:
        "Slack Clone Leveraging Chat, Channels, Teams features using NestJS/ReactJS with GraphQL",
      languages: [
        {
          name: "NestJS",
          iconifyClass: "logos-nestjs",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Typescript",
          iconifyClass: "logos-typescript-icon",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "8",
      name: "E-Commerce",
      url: "https://github.com/secmohammed/e-commerce-with-nuxt-laravel",
      description:
        "E-commerce using Laravel For API using TDD Methodology through PHPUnit and NuxtJS For the frontend.",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "NuxtJS",
          iconifyClass: "logos-nuxt-icon",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "9",
      name: "Forum API",
      url: "https://github.com/secmohammed/cross-forum-api",
      description: "Forum API Using ADR Architecture.",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "10",
      name: "Youtube Clone",
      url: "https://github.com/secmohammed/youtube-alike-application",
      description:
        "Youtube Clone using Laravel, Telestream, Aloglia, AWS S3 For API using TDD Methdology through PHPUnit, and NuxtJS For the frontend",
      languages: [
        {
          name: "NuxtJs",
          iconifyClass: "logos-nuxt-icon",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
      ],
    },
    {
      id: "11",
      name: "Simple Booking System",
      url: "https://github.com/secmohammed/youtube-alike-application",
      description: "Simple Booking System using Laravel & Livewire.",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
      ],
    },
    {
      id: "12",
      name: "Word Memorizer",
      url: "https://github.com/secmohammed/word-memorizer",
      description:
        "A simple static website related to food restaurants service. this is reasponsive as well.",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-docker-icon",
        },
      ],
    },
    {
      id: "13",
      name: "Distributed Systems PoC",
      url: "https://github.com/secmohammed/building-a-distributed-system",
      description:
        "Apply Distributed Systems concepts such as Service Discovery, Service Registry, Service Deregistration, Heartbeat checks.",
      languages: [
        {
          name: "Go",
          iconifyClass: "logos-go",
        },
        {
          name: "Docker",
          iconifyClass: "logos-docker-icon",
        },
      ],
    },
    {
      id: "14",
      name: "Reddit Clone",
      url: "https://github.com/secmohammed/mern-reddit-clone",
      description:
        "Reddit Clone Leveraging Channels, Comments, Threads & Votes features",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "NestJS",
          iconifyClass: "logos-nestjs",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      id: "15",
      name: "Twitter Clone",
      url: "https://github.com/secmohammed/react-native-graphql-twitter-clone",
      description: "Twitter clone using Node.js, ReactJS and GraphQL",
      languages: [
        {
          name: "React Native",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      id: "16",
      name: "Multi Video Chat",
      url: "https://github.com/secmohammed/vue-webrtc-multi-video-chat",
      description: "Multiple Video Chat Meeting using WebRTC",
      languages: [
        {
          name: "VueJS",
          iconifyClass: "logos-vue",
        },
      ],
    },
    {
      id: "17",
      name: "Ideas API Clone",
      url: "https://github.com/secmohammed/ideas-api",
      description: "Ideas API Clone using GraphQL",
      languages: [
        {
          name: "NestJS",
          iconifyClass: "logos-nestjs",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      id: "18",
      name: "Mobile Forum",
      url: "https://github.com/secmohammed/forum-mobile-app-and-website",
      description: "Using Native-script vue to consume Forum API",
      languages: [
        {
          name: "Native-Script-Vue",
          iconifyClass: "logos-vue",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
