import React from "react";
import "./CertificationCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";
import Lottie from "react-lottie";
const animationData = require("./not_found.json");
function CertificationCard(props) {
    const certificate = props.certificate;
    const theme = props.theme;
    const styles = style({
        boxShadow: `0px 2px 5px ${certificate.color_code}`,
        border: `1px solid ${certificate.color_code}`,
        ":hover": {
            boxShadow: `0 5px 15px ${certificate.color_code}`,
        },
    });
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <Fade bottom duration={2000} distance="20px">
            <div className="cert-card" {...styles}>
                <div className="content">
                    <a
                        href={certificate.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <div className="content-overlay"></div>
                        <div className="cert-header">
                            <object
                                className="logo_img"
                                data={certificate.thumbnail}
                                type="image/png"
                            >
                                <Lottie
                                    height={200}
                                    style={{
                                        // margin: "3px",
                                        width: "auto",
                                        // height: "100px",
                                    }}
                                    options={defaultOptions}
                                />
                            </object>
                        </div>
                    </a>
                </div>
                <div className="cert-body">
                    <a
                        href={certificate.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <h2
                            className="cert-body-title"
                            style={{ color: theme.text }}
                        >
                            {certificate.title}
                        </h2>
                        <h3
                            className="cert-body-subtitle"
                            style={{ color: theme.secondaryText }}
                        >
                            {certificate.subtitle}
                        </h3>
                    </a>
                </div>
            </div>
        </Fade>
    );
}

export default CertificationCard;
